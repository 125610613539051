(function($){

   let $body = $('body');
   let $header = $('.header');

   $body.css('padding-top', $header.innerHeight());

   $(window).on('resize', function () {
       $body.css('padding-top', $header.innerHeight());
   });

   $('.js-module-auth').on('click', function() {
      $('#module-auth').arcticmodal();
   });

   $('.js-login-modal__recover').on('click', function() {
      $('#module-auth').arcticmodal('close');
      $('#modal__recover').arcticmodal();
   });

   $('.js-auth-btn').on('click', function() {

      let successMessageAuth =  $('#success-message_auth');
      let errorMessageAuth =  $('#error-message_auth');

      successMessageAuth.empty();
      errorMessageAuth.empty();

      if( $('#login_email').val() === '' ){
         errorMessageAuth.text('Email обязателен для ввода.');
         return false;
      }
      if( $('#login_password').val() === '' ){
         errorMessageAuth.text('Пароль обязателен для ввода.');
         return false;
      }

      $.ajax({
         url: '/auth/check-form',
         data: $('#auth-from').serialize(),
         type: 'POST',
         dataType: 'json',
         success: function(response) {
            if (response.data.url){
               window.location.replace(response.data.url);
            }

            window.location.replace('/panel/desktop');
         },
         error: function(response) {
            errorMessageAuth.text(response.responseJSON.message);
         }
      });

      return false;
   });

   $('.js-modal__recover').on('click', function() {

      let successMessageRecover = $('#success-message_recover');
      let errorMessageRecover = $('#error-message_recover');

      successMessageRecover.empty();
      errorMessageRecover.empty();

      if( $('#recovery_access_email').val() === '' ){
         errorMessageRecover.text('Email обязателен для ввода.');
         return false;
      }

      $.ajax({
         url: '/auth/access_recovery',
         data: $('#recover-from').serialize(),
         type: 'POST',
         dataType: 'json',
         success: function(response) {
            successMessageRecover.text(response.message);
         },
         error: function(response) {
            errorMessageRecover.text(response.responseJSON.message);
         }
      });

      return false;
   });


   // jQuery scroll top/anchor
   $('.js-scroll-to').on('click', function() {
      let CONST_OFFSET = 0;
      let CONST_SCROLL_SPEED = 700;

      let $link = $(this);
      let linkHref = $link.attr('href');

      if ($link.hasClass('top')) {
         $('html, body').animate( {scrollTop: 0}, CONST_SCROLL_SPEED );
      } else if (linkHref[0] === '#' && linkHref.length > 1) {
         let $destinationElement = $(linkHref);

         if ($destinationElement.length) {
            let scrollTo = $destinationElement.offset().top + CONST_OFFSET;
            $('html, body').animate( {scrollTop: scrollTo}, CONST_SCROLL_SPEED );
         }

         return false;
      }

   });
   // jQuery scroll top/anchor end

   let modulesSliderOptions = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      preletrow: '<button type="button" class="slick-prev"><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30 12L18 24l12 12" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
      nextArrow: '<button type="button" class="slick-next"><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 36l12-12-12-12" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
      responsive: [
         {
           breakpoint: 993,
           settings: {
             slidesToShow: 3,
           }
         },
         {
           breakpoint: 769,
           settings: "unslick"
         }
       ]
   }
   let $modulesSlider = $('.js-modules-slider').slick(modulesSliderOptions);

   $modulesSlider.on('setPosition', function () {
      $(this).find('.slick-slide').height('auto');
      let slickTrack = $(this).find('.slick-track');
      let slickTrackHeight = $(slickTrack).height();
      $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
   });

   $(window).resize(function() {
      if ($(window).width() > 768 && !$modulesSlider.hasClass('slick-initialized')) {
         $modulesSlider = $('.js-modules-slider').slick(modulesSliderOptions);
      }
   });


   $('.js-header-menu-btn').on('click', function() {
      $body.toggleClass('menu-open');
   });

	$(document).mouseup(function (e){
		let $menu = $('.js-header-menu-btn');
		if (!$menu.is(e.target)
		    && $menu.has(e.target).length === 0) {
            $body.removeClass('menu-open');
      }
      return false;
   });

   $('.js-show-more').on('click', function() {
      let $target = $('.' + $(this).data('target'));
      $target.height($target[0].scrollHeight);

      $(this).parent().hide();

      return false;
   });

   $('.js-modules-item').on('click', function() {
      if ($(window).width() <= 768) {
         let $insertContent = $(this).clone().removeClass('js-modules-item');
         $('#module-modal').arcticmodal({
            beforeOpen: function() {
               $('.js-module-modal .modal-content').html('').append($insertContent);
           },
         });
      }

      return false;
   });

})(jQuery);
